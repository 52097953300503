import ClockAfternoonRed from "@/components/smbois/Icons/White/ClockAfternoonRed";
import HandPointingRed from "@/components/smbois/Icons/White/HandPointingRed";
import HeadsetRed from "@/components/smbois/Icons/White/HeadsetRed";
import LockKeyRed from "@/components/smbois/Icons/White/LockKeyRed";
import StarRed from "@/components/smbois/Icons/White/StarRed";
import TruckRed from "@/components/smbois/Icons/White/TruckRed";
import { Box, Link, Typography, styled } from "@mui/material";
import NextImage from "next/image";
import { FooterTopQueryFragment } from "./FooterTopQuery.gql";

const Image = styled(NextImage)({});

export default function FooterTop(props: FooterTopQueryFragment) {
  const { reinsuranceLink } = props;

  const icon = {
    clockAfternoon: (
      <ClockAfternoonRed sx={{ width: "60px", height: "60px" }} />
    ),
    truck: <TruckRed sx={{ width: "60px", height: "60px" }} />,
    lockKey: <LockKeyRed sx={{ width: "60px", height: "60px" }} />,
    handPointing: <HandPointingRed sx={{ width: "60px", height: "60px" }} />,
    star: <StarRed sx={{ width: "60px", height: "60px" }} />,
    headset: <HeadsetRed sx={{ width: "60px", height: "60px" }} />,
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        minHeight: "213px",
        width: "100%",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        gap: 3,
      }}
    >
      <Box sx={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}>
        <Image
          width={1440}
          height={311}
          src="/assets/rectagle1.webp"
          alt={"Info Background"}
          sx={{ objectFit: "cover", width: "100%", height: "100%" }}
        />
      </Box>

      {reinsuranceLink?.map((ft, index) => (
        <Link
          key={index}
          href={ft?.url as string}
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            width: "100px",
            height: "150px",
            textDecoration: "none",
            zIndex: 1,
            [theme.breakpoints.up("md")]: {
              width: "150px",
              height: "193px",
            },
          })}
        >
          <Typography component="div" sx={{ color: "transparent" }}>
            {icon[ft?.icon as string]}
          </Typography>
          <Typography
            variant="caption"
            component="p"
            sx={{
              color: "primary.contrastText",
              fontWeight: "bold",
              width: "57px",
              lineHeight: 1.5,
            }}
          >
            {ft?.title}
          </Typography>
        </Link>
      ))}
    </Box>
  );
}
