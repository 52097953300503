import { SvgIcon } from "@mui/material";

export default function Youtube(props) {
  return (
    <SvgIcon
      {...props}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      aria-label="Youtube"
    >
      <circle cx="15" cy="15" r="15" fill="white" />
      <path
        d="M7.44428 19.232C6.85191 16.4364 6.85191 13.5478 7.44428 10.7523C7.52211 10.4684 7.6725 10.2096 7.88065 10.0015C8.0888 9.79331 8.34755 9.64293 8.63144 9.56509C13.1795 8.81164 17.8205 8.81164 22.3686 9.56509C22.6525 9.64293 22.9112 9.79331 23.1194 10.0015C23.3275 10.2096 23.4779 10.4684 23.5557 10.7523C24.1481 13.5478 24.1481 16.4364 23.5557 19.232C23.4779 19.5158 23.3275 19.7746 23.1194 19.9827C22.9112 20.1909 22.6525 20.3413 22.3686 20.4191C17.8205 21.1727 13.1795 21.1727 8.63144 20.4191C8.34755 20.3413 8.0888 20.1909 7.88065 19.9827C7.6725 19.7746 7.52211 19.5158 7.44428 19.232Z"
        fill="black"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8037 17.5361L18.0436 14.9922L13.8037 12.4482V17.5361Z"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
