import { Box, Typography, styled } from "@mui/material";
import NextImage from "next/image";
import Link from "next/link";
import { FooterCenterQueryFragment } from "./FooterCenterQuery.gql";

const Image = styled(NextImage)({});
type FooterCenterProps = FooterCenterQueryFragment;

export default function FooterCenter(props: FooterCenterProps) {
  const { partners } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "text.primary",
        padding: "40px 20px",
        minHeight: 200,
      }}
    >
      <Typography
        variant="caption"
        component="p"
        sx={{
          color: "primary.contrastText",
          textAlign: "center",
          marginBottom: "32px",
          fontSize: "16px",
          lineHeight: 1.5,
        }}
      >
        Une sélection des marques distribuées par votre spécialiste bois
      </Typography>
      <Box
        sx={() => ({
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          flexWrap: "wrap",
          padding: "0.5vw",
          gap: "1.6vw",
        })}
      >
        {partners?.map((ft, index) => (
          <Typography
            key={index}
            component="div"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Link href={ft?.siteUrl || "#"}>
              <Image
                width={110}
                height={64}
                src={ft?.imageUrl || "#"}
                sx={(theme) => ({
                  borderRadius: "4px",
                  maxWidth: "100%",
                  height: "auto",
                  [theme.breakpoints.up("md")]: {
                    maxWidth: "100%",
                    height: "auto",
                  },
                })}
                alt={"Partner"}
              />
            </Link>
          </Typography>
        ))}
      </Box>
    </Box>
  );
}
