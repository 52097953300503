import { SvgIcon, useTheme } from "@mui/material";

export default function Logo(props) {
  const theme = useTheme();
  return (
    <SvgIcon
      {...props}
      x="0px"
      y="0px"
      width="96px"
      height="96px"
      viewBox="0 0 96 96"
      enableBackground="new 0 0 96 96"
      aria-label="SMBois Logo"
    >
      <g>
        <path
          fill={theme.palette.primary.main}
          d="M54.737,25.268c0.287,0.54,0.408,1.105,0.25,1.708c-0.027,0.103-0.096,0.194-0.146,0.291
		c-0.174,0.032-0.21,0.106-0.071,0.233c-0.176,0.442-0.499,0.766-0.86,1.058c-0.026-0.037-0.052-0.073-0.077-0.11
		c-0.023,0.039-0.054,0.075-0.067,0.117c-0.01,0.033,0.002,0.073,0.004,0.11c-0.473,0.444-1.041,0.743-1.604,1.05
		c-0.177,0.096-0.359,0.184-0.539,0.275c-0.079-0.036-0.158-0.072-0.237-0.109c-0.029,0.073-0.057,0.147-0.086,0.22
		c-0.486,0.209-0.968,0.427-1.46,0.623c-0.352,0.14-0.716,0.25-1.075,0.373c-0.217-0.121-0.244-0.111-0.297,0.113
		c-1.248,0.472-2.522,0.852-3.832,1.109c-0.438,0.086-0.875,0.176-1.313,0.264c-0.088-0.137-0.137-0.095-0.166,0.037
		c-0.557,0.14-1.118,0.27-1.671,0.423c-0.587,0.162-1.167,0.345-1.751,0.519c-0.112-0.005-0.239-0.04-0.264,0.13
		c-0.255,0.11-0.51,0.219-0.764,0.329c-0.175-0.116-0.205-0.099-0.234,0.136c-0.407,0.281-0.791,0.587-1.06,1.013
		c-0.119,0.015-0.219,0.037-0.063,0.16c-0.185,0.927,0.195,1.506,1.028,1.9c0.582,0.276,1.207,0.401,1.841,0.469
		c0.645,0.069,1.294,0.107,1.941,0.159c0.044,0.045,0.088,0.091,0.132,0.136c0.033-0.045,0.066-0.089,0.098-0.134
		c0.461-0.001,0.922-0.001,1.383-0.002c0.02,0.039,0.04,0.078,0.06,0.116c0.031-0.039,0.063-0.078,0.094-0.117
		c0.768-0.024,1.536-0.048,2.304-0.072c0.033,0.044,0.066,0.088,0.099,0.133c0.044-0.046,0.087-0.091,0.131-0.137
		c1.178-0.024,2.355-0.049,3.533-0.073c0.033,0.045,0.066,0.089,0.099,0.134c0.044-0.046,0.088-0.091,0.132-0.137
		c0.677-0.029,1.353-0.077,2.03-0.082c1.342-0.009,2.685-0.024,4.026,0.023c0.659,0.023,1.319,0.152,1.967,0.285
		c0.354,0.072,0.706,0.215,1.022,0.393c0.779,0.439,0.98,1.272,0.509,2.039c-0.279,0.454-0.671,0.799-1.15,1.039
		c-0.057-0.022-0.118-0.071-0.17-0.062c-0.126,0.02-0.229,0.073-0.078,0.209c-0.491,0.377-1.082,0.543-1.648,0.75
		c-1.377,0.504-2.802,0.842-4.231,1.157c-0.305,0.067-0.598,0.195-0.92,0.173c-0.103-0.016-0.187-0.001-0.204,0.124
		c-0.435,0.085-0.871,0.169-1.305,0.257c-0.149,0.03-0.296,0.073-0.444,0.109c-0.057-0.033-0.114-0.066-0.171-0.098
		c-0.022,0.052-0.045,0.105-0.067,0.157c-1.047,0.319-2.095,0.637-3.141,0.959c-0.57,0.175-1.138,0.356-1.706,0.535
		c-0.027-0.037-0.053-0.075-0.08-0.112c-0.05,0.063-0.1,0.126-0.15,0.189c-0.62,0.232-1.24,0.465-1.861,0.696
		c-0.116,0.043-0.237,0.075-0.355,0.112c-0.176-0.139-0.199-0.128-0.242,0.112c-0.74,0.293-1.479,0.586-2.219,0.879
		c-0.166-0.129-0.202-0.112-0.239,0.112c-0.099,0.04-0.199,0.08-0.298,0.12c-0.12-0.142-0.143-0.047-0.159,0.065
		c-0.177,0.081-0.355,0.161-0.532,0.242c-0.064-0.049-0.127-0.097-0.191-0.146c-0.041,0.096-0.081,0.192-0.122,0.288
		c-0.492,0.241-0.984,0.483-1.477,0.723c-2.139,1.044-4.215,2.202-6.239,3.454c-0.162,0.1-0.331,0.189-0.498,0.283
		c-0.187-0.119-0.207-0.107-0.234,0.147c-0.406,0.263-0.811,0.529-1.22,0.789c-0.463,0.294-0.93,0.583-1.395,0.874
		c-0.026-0.137-0.108-0.157-0.215-0.094c-0.129,0.077-0.183,0.196-0.167,0.346c-0.556,0.428-1.121,0.846-1.666,1.287
		c-0.637,0.516-1.237,1.079-1.713,1.75c-0.308,0.435-0.57,0.906-0.821,1.378c-0.103,0.194-0.123,0.433-0.18,0.652
		c-0.04,0.023-0.081,0.046-0.121,0.069c0.035,0.028,0.069,0.056,0.104,0.085c0.013,0.367-0.006,0.739,0.047,1.1
		c0.091,0.613,0.364,1.156,0.756,1.638c0.072,0.088,0.14,0.178,0.21,0.267c-0.012,0.163,0.064,0.237,0.226,0.224
		c0.541,0.355,1.082,0.709,1.623,1.064c0.074,0.258,0.119,0.278,0.313,0.142c0.815,0.249,1.625,0.518,2.448,0.741
		c0.502,0.136,1.024,0.203,1.537,0.301c0.041,0.067,0.068,0.173,0.125,0.193c0.103,0.035,0.234,0.038,0.259-0.128
		c0.356,0.026,0.713,0.045,1.068,0.079c1.378,0.129,2.758,0.137,4.139,0.061c0.523-0.029,1.048-0.034,1.57-0.079
		c0.468-0.041,0.931-0.127,1.398-0.184c2.844-0.349,5.645-0.914,8.415-1.64c0.024-0.006,0.051-0.001,0.076-0.002
		c0.025,0.036,0.049,0.073,0.074,0.109c0.024-0.048,0.048-0.095,0.072-0.143c0.942-0.276,1.888-0.543,2.827-0.829
		c3.37-1.028,6.681-2.224,9.935-3.574c1.963-0.815,3.916-1.654,5.872-2.486c2.708-1.152,5.314-2.498,7.797-4.083
		c0.611-0.39,1.268-0.716,1.925-1.027c0.528-0.25,1.099-0.325,1.683-0.183c1.208,0.294,1.948,1.082,2.376,2.215
		c0.189,0.501,0.309,1.014,0.366,1.544c-0.019,0.026-0.037,0.052-0.056,0.078c-0.301-0.015-0.607-0.001-0.902-0.052
		c-0.452-0.078-0.865-0.281-1.285-0.461c-0.537-0.23-1.078-0.462-1.637-0.626c-1.006-0.296-2.001-0.21-2.942,0.263
		c-0.605,0.304-1.204,0.623-1.781,0.977c-1.454,0.892-3.014,1.569-4.559,2.277c-1.459,0.669-2.92,1.331-4.378,2.002
		c-2.098,0.966-4.196,1.932-6.288,2.91c-2,0.935-3.979,1.913-5.991,2.822c-1.721,0.778-3.491,1.437-5.289,2.019
		c-2.215,0.717-4.473,1.247-6.774,1.603c-0.791,0.123-1.584,0.199-2.38,0.237c-1.033,0.05-2.069,0.052-3.104,0.075
		c-0.055-0.065-0.11-0.129-0.165-0.194c-0.022,0.064-0.044,0.127-0.065,0.191c-0.943-0.058-1.888-0.088-2.828-0.18
		c-1.364-0.133-2.688-0.475-3.99-0.895c-0.274-0.088-0.527-0.244-0.789-0.368c-0.015-0.105-0.03-0.209-0.156-0.072
		c-0.462-0.252-0.924-0.504-1.386-0.756c-0.002-0.062,0.012-0.132-0.011-0.185c-0.021-0.048-0.084-0.077-0.128-0.114
		c-0.03,0.048-0.06,0.097-0.09,0.145c-0.609-0.379-1.138-0.848-1.585-1.409c0.035-0.027,0.069-0.053,0.104-0.08
		c-0.038-0.023-0.073-0.054-0.114-0.067c-0.032-0.01-0.071,0.001-0.107,0.002c-0.51-0.564-0.895-1.203-1.149-1.919
		c0.026-0.049,0.052-0.097,0.078-0.146c-0.059-0.027-0.118-0.054-0.178-0.08c-0.074-0.208-0.167-0.411-0.216-0.625
		c-0.106-0.456-0.19-0.916-0.282-1.375c-0.02-0.028-0.041-0.056-0.061-0.084c0-0.872,0-1.743,0.001-2.615
		c0.02-0.028,0.04-0.056,0.06-0.084c0.104-0.918,0.276-1.821,0.634-2.679c0.299-0.096,0.322-0.149,0.204-0.482
		c0.122-0.252,0.243-0.505,0.365-0.757c0.098-0.011,0.24,0.004,0.088-0.151c0.246-0.385,0.467-0.789,0.743-1.15
		c1.258-1.649,2.903-2.822,4.72-3.775c0.15,0.15,0.142,0.017,0.153-0.085c0.532-0.275,1.052-0.578,1.599-0.82
		c1.475-0.654,2.958-1.29,4.444-1.919c2.126-0.899,4.257-1.787,6.238-2.987c0.545-0.33,1.072-0.689,1.606-1.037
		c0.039-0.025,0.06-0.078,0.119-0.16c-0.179-0.092-0.338-0.189-0.508-0.259c-0.766-0.319-1.559-0.548-2.374-0.702
		c-0.199-0.038-0.396-0.089-0.594-0.134c-0.039-0.054-0.078-0.107-0.118-0.161c-0.038,0.042-0.075,0.084-0.113,0.126
		c-0.41-0.07-0.819-0.14-1.229-0.21c-0.029-0.043-0.058-0.085-0.087-0.128c-0.022,0.037-0.044,0.075-0.066,0.112
		c-0.256-0.045-0.512-0.091-0.768-0.136c-0.029-0.043-0.058-0.086-0.086-0.129c-0.022,0.037-0.045,0.075-0.067,0.112
		c-0.618-0.137-1.237-0.274-1.855-0.412c-0.027-0.059-0.053-0.118-0.08-0.177c-0.046,0.041-0.093,0.082-0.139,0.123
		c-0.231-0.076-0.462-0.152-0.693-0.228c-0.026-0.021-0.052-0.042-0.078-0.063c-0.101-0.056-0.203-0.112-0.304-0.168
		c-0.029-0.229-0.058-0.246-0.232-0.136c-0.88-0.465-1.399-1.203-1.604-2.165c-0.053-0.247-0.052-0.505-0.075-0.757
		c0.067-0.048,0.184-0.09,0.189-0.145c0.012-0.113-0.045-0.224-0.189-0.239c0.004-0.911,0.32-1.7,0.925-2.377
		c0.174,0.01,0.236-0.079,0.215-0.242c0.112-0.127,0.223-0.253,0.335-0.38c0.022-0.009,0.038-0.024,0.048-0.045
		c0.862-0.693,1.812-1.24,2.829-1.67c0.128-0.054,0.26-0.098,0.39-0.147c0.12,0.145,0.144,0.053,0.158-0.064
		c0,0-0.004,0.004-0.004,0.004c0.028-0.02,0.056-0.04,0.084-0.06c1.72-0.698,3.508-1.195,5.268-1.774
		c0.856-0.282,1.706-0.579,2.558-0.87c0.164,0.133,0.202,0.117,0.24-0.107c0.554-0.286,1.115-0.56,1.659-0.864
		c0.263-0.147,0.496-0.35,0.754-0.536c-0.105-0.381-0.357-0.64-0.551-0.931c0.253-0.109,0.05-0.228-0.01-0.312
		c-0.064-0.089-0.173-0.113-0.23,0.033c-0.005,0.011-0.004,0.025-0.006,0.037c-0.781-0.622-1.701-0.859-2.67-0.892
		c-1.132-0.038-2.267-0.022-3.4-0.03c-0.053-0.216-0.224-0.117-0.353-0.132c-0.37,0.01-0.695-0.135-1.009-0.305
		c-0.726-0.393-1.257-0.982-1.661-1.694c-0.244-0.43-0.416-0.884-0.515-1.368c-0.019-0.092-0.081-0.175-0.122-0.261
		c0,0,0.024-0.025,0.024-0.025c0.01-0.037,0.019-0.074,0.029-0.112c0-0.361-0.001-0.722-0.001-1.082
		c0.04-0.03,0.081-0.061,0.121-0.091c-0.038-0.021-0.076-0.042-0.114-0.062c-0.002-0.463,0.174-0.871,0.404-1.257
		c0.408-0.685,0.963-1.238,1.588-1.724c0.025,0.036,0.051,0.072,0.076,0.108c0.023-0.038,0.055-0.073,0.068-0.114
		c0.011-0.033-0.001-0.072-0.003-0.109c0.329-0.257,0.641-0.538,0.989-0.765c0.762-0.499,1.543-0.97,2.316-1.453
		c0.157,0.146,0.14,0.005,0.15-0.091c0.547-0.324,1.09-0.656,1.642-0.97c1.132-0.645,2.269-1.282,3.406-1.918
		c0.408-0.228,0.821-0.447,1.234-0.666c0.721-0.381,1.442-0.764,2.169-1.135c0.405-0.207,0.821-0.39,1.233-0.584
		c0.026-0.019,0.052-0.038,0.077-0.057c0.027-0.001,0.053-0.003,0.08-0.004c0.026,0.036,0.051,0.072,0.077,0.108
		c0.026-0.061,0.051-0.122,0.077-0.183c0.973-0.438,1.946-0.876,2.918-1.315c0.029,0.16,0.127,0.182,0.23,0.083
		c0.052-0.05,0.053-0.153,0.077-0.233c0.411-0.188,0.823-0.381,1.293-0.329c0.16,0.018,0.315,0.07,0.473,0.106
		c0.027,0.06,0.053,0.12,0.08,0.18c0.025-0.037,0.049-0.074,0.074-0.111c0.176,0.125,0.352,0.25,0.527,0.375
		c-0.04,0.024-0.115,0.05-0.114,0.073c0.011,0.22,0.137,0.291,0.378,0.221c0.255,0.274,0.426,0.595,0.516,0.956
		c0.094,0.377,0.192,0.754,0.257,1.136c0.093,0.549-0.002,1.084-0.181,1.606c-0.019,0.025-0.038,0.051-0.057,0.076
		c-0.026,0.028-0.051,0.056-0.077,0.083c-0.274,0.119-0.303,0.217-0.112,0.376c-0.147,0.358-0.448,0.567-0.752,0.773
		c-0.023,0.024-0.047,0.048-0.07,0.072c-0.575,0.3-1.136,0.635-1.73,0.892c-0.766,0.331-1.559,0.598-2.339,0.898
		c-1.122,0.432-2.236,0.887-3.366,1.297c-0.905,0.328-1.831,0.599-2.744,0.906c-0.528,0.178-1.048,0.377-1.571,0.567
		c-0.014-0.146-0.089-0.21-0.209-0.107c-0.06,0.052-0.07,0.161-0.103,0.244c-0.461,0.278-0.922,0.557-1.382,0.835
		c-0.359,0.049-0.36,0.051-0.389,0.379c-0.144,0.277-0.364,0.506-0.411,0.844c-0.099,0.722,0.121,1.304,0.736,1.677
		c0.354,0.214,0.762,0.369,1.166,0.464c0.542,0.127,1.105,0.169,1.658,0.248c0.043,0.046,0.087,0.092,0.13,0.138
		c0.033-0.044,0.067-0.088,0.1-0.131c0.282-0.005,0.563-0.01,0.845-0.016c0.051,0,0.102,0,0.154,0c0.179,0,0.358,0,0.538,0
		c0.051,0,0.102,0,0.154-0.001c0.461,0.005,0.922,0.01,1.382,0.015c0.059,0.07,0.107,0.185,0.178,0.202
		c0.136,0.032,0.194-0.08,0.206-0.207c1.293-0.031,2.586-0.074,3.87,0.141c0.1,0.017,0.201,0.023,0.302,0.033
		c0.069,0.035,0.138,0.069,0.207,0.104c0.054,0.007,0.107,0.015,0.161,0.022c0.022,0.016,0.044,0.032,0.065,0.047
		c0.126,0.037,0.253,0.075,0.379,0.112c0.027,0.015,0.055,0.03,0.082,0.045c0.029,0.009,0.058,0.018,0.087,0.026
		c0.022,0.016,0.045,0.033,0.067,0.049c0.028,0.009,0.056,0.019,0.084,0.028c0.024,0.022,0.047,0.044,0.071,0.067
		c0.506,0.405,0.964,0.854,1.296,1.417c0.038,0.065,0.064,0.136,0.095,0.205c-0.174,0.028-0.183,0.105-0.05,0.2
		C54.607,25.266,54.678,25.258,54.737,25.268z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M38.949,93.694c-0.003-0.843-0.006-1.685-0.01-2.528c-0.001-0.14-0.011-0.28-0.017-0.419
		c-0.03-0.055-0.061-0.111-0.091-0.166c-0.04,0.066-0.08,0.131-0.12,0.197c-0.098,0.259-0.203,0.516-0.292,0.778
		c-0.25,0.733-0.493,1.467-0.742,2.213c-0.662,0-1.311,0-2.005,0c-0.369-1.145-0.741-2.297-1.113-3.45
		c-0.026-0.112-0.051-0.223-0.077-0.335c-0.024,0.005-0.048,0.01-0.072,0.015c0.006,0.081,0.012,0.163,0.018,0.244
		c0.013,0.639,0.031,1.277,0.038,1.916c0.006,0.522,0.001,1.045,0.001,1.559c-0.295,0.083-2.743,0.104-3.277,0.032
		c0-2.304,0-4.612,0-6.975c1.461-0.006,2.924-0.001,4.431-0.003c0.376,1.094,0.667,2.214,1.016,3.315
		c0.005,0.046,0.01,0.093,0.014,0.139c0.014,0,0.028,0.001,0.041,0.001c0.008-0.046,0.016-0.092,0.024-0.138
		c0.051-0.112,0.116-0.22,0.15-0.336c0.292-0.978,0.578-1.958,0.872-2.958c1.482,0,2.96,0,4.465,0c0,2.309,0,4.617,0,6.926
		c-0.269,0.085-2.067,0.115-3.19,0.051C38.993,93.746,38.971,93.72,38.949,93.694z M38.935,90.24
		c0.108-0.09,0.101-0.178-0.036-0.339c-0.017,0.16-0.027,0.252-0.036,0.343c-0.087,0.22-0.087,0.22,0.035,0.355
		c0.015-0.036,0.038-0.066,0.04-0.098C38.942,90.415,38.937,90.327,38.935,90.24z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M66.466,77.709c-0.078,0.53-0.155,1.16-0.266,1.784c-0.074,0.415-0.146,0.456-0.567,0.527
		c-0.314,0.053-0.626,0.139-0.942,0.159c-0.79,0.051-1.351-0.315-1.69-1.018c-0.105-0.217-0.179-0.451-0.252-0.682
		c-0.251-0.786-0.241-1.607-0.293-2.417c-0.032-0.497-0.038-0.998-0.024-1.496c0.037-1.262-0.215-2.477-0.569-3.678
		c-0.335-1.137-0.682-2.27-0.99-3.415c-0.166-0.616-0.265-1.248-0.132-1.896c0.063-0.306,0.214-0.543,0.45-0.724
		c0.406-0.311,1.155-0.469,1.721,0.073c0.464,0.444,0.746,0.996,0.985,1.581c0.726,1.776,1.02,3.663,1.453,5.515
		c0.25,1.068,0.546,2.126,0.788,3.195c0.112,0.495,0.149,1.008,0.216,1.513C66.393,77.021,66.421,77.312,66.466,77.709z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M54.613,93.543c-0.009,0.063-0.018,0.126-0.033,0.226c-1.064,0-2.132,0-3.2,0
		c-0.022-0.024-0.044-0.049-0.066-0.073c-0.005-0.293-0.013-0.585-0.013-0.878c-0.001-1.949,0-3.899-0.001-5.848
		c0-0.14-0.007-0.28-0.011-0.42l0.031,0.008c0.02-0.026,0.04-0.051,0.061-0.077c1.065,0,2.13,0,3.247,0c0,0.647,0,1.278,0,1.909
		c-0.005,0.066-0.009,0.132-0.014,0.198c0.019,0.006,0.038,0.013,0.058,0.019c0.034-0.067,0.069-0.134,0.103-0.201
		c0.366-0.138,0.651-0.436,1.055-0.504c0.758-0.128,1.51-0.215,2.265,0.022c0.828,0.26,1.309,0.816,1.507,1.656
		c0.189,0.803,0.156,1.598-0.01,2.393c-0.092,0.44-0.27,0.85-0.56,1.2c-0.323,0.389-0.755,0.604-1.235,0.719
		c-0.654,0.158-1.321,0.194-1.977,0.04c-0.342-0.08-0.662-0.255-0.992-0.387c-0.061-0.054-0.122-0.108-0.212-0.189
		C54.613,93.449,54.613,93.496,54.613,93.543z M56.512,91.085c0-0.534-0.194-0.873-0.581-1.02c-0.79-0.3-1.509,0.159-1.528,0.999
		c-0.012,0.541,0.17,0.972,0.673,1.11C55.883,92.395,56.513,91.924,56.512,91.085z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M22.073,89.12c0.22,0.043,0.451,0.111,0.687,0.128c0.459,0.034,0.922,0.021,1.379,0.062
		c0.491,0.045,0.982,0.098,1.443,0.315c0.553,0.259,0.843,0.692,0.916,1.276c0.161,1.302-0.374,2.223-1.606,2.678
		c-0.474,0.175-0.985,0.271-1.488,0.34c-1.058,0.146-2.118,0.062-3.175-0.05c-0.53-0.056-1.058-0.122-1.607-0.185
		c0-0.671,0-1.304,0-1.969c0.073,0,0.146-0.01,0.215,0.002c0.564,0.093,1.125,0.232,1.693,0.275
		c0.648,0.049,1.302,0.014,1.953,0.011c0.061,0,0.142-0.012,0.18-0.05c0.065-0.066,0.145-0.159,0.142-0.239
		c-0.003-0.08-0.084-0.202-0.157-0.225c-0.167-0.053-0.349-0.074-0.526-0.079c-0.665-0.016-1.327-0.027-1.985-0.154
		c-0.461-0.089-0.871-0.285-1.269-0.513c-0.08-0.046-0.138-0.144-0.188-0.229c-0.59-0.982-0.448-1.923,0.168-2.833
		c0.039-0.058,0.12-0.086,0.178-0.133c0.698-0.56,1.535-0.724,2.389-0.829c0.982-0.121,1.967-0.081,2.949,0.003
		c0.617,0.052,1.23,0.148,1.863,0.226c0,0.636,0,1.243,0,1.893c-0.557-0.061-1.094-0.132-1.633-0.174
		c-0.762-0.061-1.525-0.11-2.284,0.035C22.078,88.744,22.055,88.785,22.073,89.12z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M64.377,94.001c-0.358,0-0.727,0.058-1.071-0.012c-0.798-0.161-1.598-0.348-2.205-0.964
		c-0.303-0.308-0.518-0.668-0.635-1.079c-0.2-0.702-0.213-1.412-0.025-2.12c0.188-0.708,0.614-1.228,1.269-1.553
		c0.473-0.235,0.975-0.384,1.505-0.411c0.622-0.032,1.247-0.092,1.868-0.067c0.607,0.024,1.217,0.096,1.797,0.318
		c0.909,0.347,1.49,0.973,1.661,1.94c0.119,0.673,0.108,1.342-0.107,2.004c-0.224,0.688-0.699,1.14-1.308,1.48
		c-0.383,0.214-0.812,0.292-1.233,0.362c-0.499,0.084-1.008,0.105-1.512,0.154C64.379,94.035,64.378,94.018,64.377,94.001z
		 M63.403,90.905c0.04,0.212,0.059,0.431,0.126,0.634c0.093,0.282,0.297,0.468,0.59,0.554c0.508,0.149,1.01-0.022,1.238-0.431
		c0.275-0.493,0.184-1.305-0.183-1.608c-0.058-0.048-0.126-0.091-0.195-0.119C64.372,89.689,63.391,89.863,63.403,90.905z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M73.961,92.096c1.025,0.153,2.054,0.263,3.093,0.229c0.102-0.003,0.205,0.001,0.307-0.007
		c0.361-0.028,0.388-0.068,0.318-0.498c-0.113-0.011-0.237-0.031-0.36-0.033c-0.639-0.008-1.281,0.016-1.918-0.024
		c-0.546-0.034-0.994-0.299-1.381-0.701c-0.733-0.763-0.467-2.819,0.958-3.078c0.412-0.075,0.826-0.161,1.242-0.186
		c1.421-0.085,2.838,0.008,4.251,0.161c0.023,0.003,0.045,0.021,0.109,0.053c0,0.268,0,0.557,0,0.846c0,0.279,0,0.559,0,0.88
		c-0.57-0.052-1.096-0.115-1.625-0.144c-0.498-0.027-0.997-0.025-1.496-0.023c-0.125,0.001-0.261,0.027-0.369,0.086
		c-0.068,0.037-0.125,0.155-0.125,0.237c0,0.061,0.09,0.153,0.158,0.176c0.106,0.034,0.227,0.024,0.341,0.027
		c0.639,0.016,1.281-0.004,1.916,0.054c0.971,0.088,1.457,0.652,1.461,1.626c0.002,0.466-0.1,0.911-0.373,1.296
		c-0.298,0.421-0.755,0.586-1.231,0.703c-0.963,0.237-1.943,0.232-2.925,0.195c-0.396-0.015-0.793-0.015-1.187-0.052
		c-0.372-0.035-0.74-0.108-1.133-0.168c-0.033-0.547-0.064-1.068-0.095-1.589C73.92,92.141,73.94,92.118,73.961,92.096z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M72.727,87.955c0.003,1.879,0.005,3.757,0.007,5.636c0,0.049-0.011,0.098-0.019,0.17
		c-1.103,0-2.196,0-3.317,0c0-1.924,0-3.847,0-5.751c0.863,0.005,1.725,0.017,2.587,0.011c0.24-0.002,0.48-0.055,0.719-0.084
		C72.704,87.937,72.727,87.955,72.727,87.955z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M44.629,93.969c-0.253-0.046-0.526-0.063-0.775-0.148c-0.673-0.231-0.668-0.993-0.357-1.432
		c0.078-0.11,0.251-0.172,0.393-0.215c0.487-0.148,0.983-0.153,1.471-0.014c0.432,0.123,0.635,0.41,0.634,0.845
		c0,0.436-0.202,0.721-0.635,0.842C45.13,93.912,44.886,93.928,44.629,93.969z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M28.722,93.964c-0.243-0.036-0.458-0.059-0.67-0.1c-0.416-0.081-0.677-0.395-0.698-0.825
		c-0.02-0.404,0.208-0.761,0.606-0.874c0.499-0.141,1.011-0.149,1.511,0.007c0.387,0.121,0.581,0.422,0.578,0.843
		c-0.004,0.428-0.192,0.709-0.598,0.825C29.209,93.91,28.951,93.927,28.722,93.964z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M69.531,86.37c0.119-0.013,0.22-0.033,0.32-0.033c0.791-0.003,1.582-0.005,2.373,0
		c0.38,0.002,0.433,0.061,0.445,0.451c0.005,0.14,0.005,0.281-0.004,0.421c-0.02,0.3-0.066,0.35-0.374,0.353
		c-0.676,0.007-1.353,0.003-2.029,0.002c-0.179,0-0.357-0.001-0.536-0.003c-0.145-0.002-0.258-0.068-0.271-0.216
		C69.428,87.032,69.379,86.712,69.531,86.37z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M43.472,26.33c0.002-0.012,0.002-0.026,0.006-0.037c0.058-0.146,0.167-0.122,0.23-0.033
		c0.06,0.084,0.263,0.203,0.01,0.312C43.637,26.491,43.554,26.41,43.472,26.33z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M55.319,8.514c-0.241,0.07-0.367-0.001-0.378-0.221c-0.001-0.022,0.074-0.048,0.114-0.073
		C55.143,8.318,55.231,8.416,55.319,8.514z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M26.65,54.761c-0.016-0.15,0.038-0.269,0.167-0.346c0.108-0.064,0.189-0.043,0.215,0.094
		C26.905,54.593,26.778,54.677,26.65,54.761z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M19.805,55.352c0.118,0.333,0.096,0.386-0.204,0.482C19.669,55.673,19.737,55.513,19.805,55.352z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M41.01,19.504c0.029-0.329,0.03-0.33,0.389-0.379C41.27,19.251,41.14,19.378,41.01,19.504z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M47.846,22.737c-0.012,0.127-0.07,0.239-0.206,0.207c-0.071-0.017-0.119-0.131-0.178-0.202
		C47.59,22.74,47.718,22.739,47.846,22.737z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M28.809,36.634c0.145,0.015,0.202,0.126,0.189,0.239c-0.006,0.054-0.123,0.097-0.189,0.145
		C28.809,36.89,28.809,36.762,28.809,36.634z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M29.798,65.522c-0.025,0.166-0.156,0.163-0.259,0.128c-0.057-0.02-0.085-0.126-0.125-0.193
		C29.542,65.479,29.67,65.5,29.798,65.522z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M42.782,18.29c0.033-0.083,0.043-0.192,0.103-0.244c0.119-0.103,0.195-0.039,0.209,0.107
		C42.989,18.199,42.886,18.244,42.782,18.29z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M38.095,48.238c0.041-0.096,0.081-0.192,0.122-0.288c0.064,0.049,0.127,0.097,0.191,0.146
		C38.304,48.143,38.199,48.19,38.095,48.238z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M55.665,12.748c-0.192-0.16-0.163-0.258,0.112-0.376C55.739,12.497,55.702,12.623,55.665,12.748z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M52.608,7.999c-0.024,0.079-0.024,0.183-0.077,0.233c-0.103,0.099-0.201,0.077-0.23-0.083
		C52.403,8.099,52.506,8.049,52.608,7.999z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M51.305,30.112c0.029-0.073,0.057-0.147,0.086-0.22c0.079,0.036,0.158,0.072,0.237,0.109
		C51.52,30.038,51.412,30.075,51.305,30.112z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M22.417,66.995c0.03-0.048,0.06-0.097,0.09-0.145c0.044,0.037,0.107,0.066,0.128,0.114
		c0.024,0.053,0.009,0.123,0.011,0.185C22.57,67.098,22.494,67.046,22.417,66.995z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M58.383,41.556c-0.151-0.135-0.047-0.189,0.078-0.209c0.052-0.008,0.113,0.04,0.17,0.062
		C58.549,41.459,58.466,41.507,58.383,41.556z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M25.43,64.415c-0.194,0.137-0.239,0.116-0.313-0.142C25.221,64.32,25.325,64.367,25.43,64.415z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M54.737,25.268c-0.059-0.01-0.131-0.002-0.174-0.033c-0.133-0.096-0.124-0.172,0.05-0.2
		C54.654,25.112,54.696,25.19,54.737,25.268z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M31.795,69.42c0.022-0.064,0.044-0.127,0.065-0.191c0.055,0.065,0.11,0.129,0.165,0.194
		C31.949,69.422,31.872,69.421,31.795,69.42z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M48.473,31.221c0.053-0.224,0.08-0.234,0.297-0.113C48.671,31.145,48.572,31.183,48.473,31.221z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M29.647,52.846c0.027-0.254,0.047-0.266,0.234-0.147C29.804,52.748,29.725,52.797,29.647,52.846z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M30.488,39.94c0.174-0.11,0.203-0.093,0.232,0.136C30.643,40.03,30.565,39.985,30.488,39.94z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M41.857,28.903c-0.039,0.224-0.076,0.241-0.24,0.107C41.696,28.974,41.776,28.939,41.857,28.903z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M38.477,34.166c0.029-0.234,0.059-0.252,0.234-0.136C38.633,34.076,38.555,34.121,38.477,34.166z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M39.398,47.669c0.037-0.225,0.073-0.241,0.239-0.112C39.557,47.594,39.477,47.631,39.398,47.669z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M41.856,46.677c0.043-0.239,0.066-0.25,0.242-0.112C42.017,46.602,41.936,46.64,41.856,46.677z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M44.314,45.757c0.05-0.063,0.1-0.126,0.15-0.189c0.027,0.037,0.053,0.075,0.08,0.112
		C44.467,45.706,44.39,45.732,44.314,45.757z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M35.934,17.18c0.002,0.036,0.013,0.076,0.003,0.109c-0.013,0.041-0.045,0.076-0.068,0.114
		c-0.025-0.036-0.051-0.072-0.076-0.108C35.839,17.256,35.886,17.218,35.934,17.18z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M42.394,37.869c-0.033,0.045-0.066,0.089-0.098,0.134c-0.044-0.045-0.088-0.091-0.132-0.136
		C42.24,37.868,42.317,37.869,42.394,37.869z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M31.795,40.535c0.046-0.041,0.093-0.082,0.139-0.123c0.027,0.059,0.053,0.118,0.08,0.177
		C31.941,40.571,31.868,40.553,31.795,40.535z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M53.77,28.675c-0.002-0.037-0.014-0.077-0.004-0.11c0.013-0.042,0.044-0.078,0.067-0.117
		c0.026,0.037,0.052,0.073,0.077,0.11C53.864,28.597,53.817,28.636,53.77,28.675z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M44.39,22.743c-0.033,0.044-0.067,0.088-0.1,0.131c-0.043-0.046-0.087-0.092-0.13-0.138
		C44.237,22.739,44.314,22.741,44.39,22.743z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M36.173,41.38c0.038-0.042,0.075-0.084,0.113-0.126c0.039,0.054,0.078,0.107,0.118,0.161
		C36.326,41.403,36.25,41.392,36.173,41.38z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M37.049,25.276c0.128,0.015,0.299-0.085,0.353,0.132C37.284,25.364,37.167,25.32,37.049,25.276z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M49.391,44.187c0.022-0.052,0.045-0.105,0.067-0.157c0.057,0.033,0.114,0.066,0.171,0.098
		C49.55,44.147,49.471,44.167,49.391,44.187z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M46.464,37.79c-0.044,0.046-0.087,0.091-0.131,0.137c-0.033-0.044-0.066-0.088-0.099-0.133
		C46.31,37.793,46.387,37.791,46.464,37.79z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M50.227,37.713c-0.044,0.046-0.088,0.091-0.132,0.137c-0.033-0.045-0.066-0.089-0.099-0.134
		C50.074,37.715,50.15,37.714,50.227,37.713z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M54.771,27.5c-0.139-0.127-0.103-0.201,0.071-0.233C54.818,27.344,54.794,27.422,54.771,27.5z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M19.466,63.296c0.059,0.027,0.118,0.054,0.178,0.08c-0.026,0.049-0.052,0.097-0.078,0.146
		C19.532,63.447,19.499,63.371,19.466,63.296z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M23.494,63.209c-0.162,0.013-0.239-0.06-0.226-0.224C23.343,63.06,23.418,63.134,23.494,63.209z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M29.949,34.015c0.02,0.163-0.041,0.252-0.215,0.242C29.806,34.176,29.878,34.095,29.949,34.015z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M49.383,9.464c-0.026,0.061-0.051,0.122-0.077,0.183c-0.026-0.036-0.051-0.072-0.077-0.108
		C49.28,9.514,49.331,9.489,49.383,9.464z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M20.715,65.442c0.036-0.001,0.075-0.012,0.107-0.002c0.041,0.013,0.076,0.044,0.114,0.067
		c-0.035,0.027-0.069,0.053-0.104,0.08C20.793,65.538,20.754,65.49,20.715,65.442z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M54.528,7.846c-0.025,0.037-0.049,0.074-0.074,0.111c-0.027-0.06-0.053-0.12-0.08-0.18
		C54.425,7.799,54.476,7.822,54.528,7.846z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M39.475,33.702c0.026-0.17,0.152-0.135,0.264-0.13C39.652,33.615,39.563,33.658,39.475,33.702z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M33.8,20.275c0.038,0.021,0.076,0.042,0.114,0.062c-0.04,0.03-0.081,0.061-0.121,0.091
		C33.795,20.378,33.798,20.326,33.8,20.275z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M43.93,37.867c-0.031,0.039-0.063,0.078-0.094,0.117c-0.02-0.039-0.04-0.078-0.06-0.116
		C43.827,37.867,43.878,37.867,43.93,37.867z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M20.257,54.444c0.152,0.155,0.01,0.141-0.088,0.151C20.199,54.545,20.228,54.494,20.257,54.444z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M43.162,32.63c0.029-0.131,0.078-0.174,0.166-0.037C43.272,32.605,43.217,32.618,43.162,32.63z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M22.254,59.981c-0.035-0.028-0.069-0.056-0.104-0.085c0.04-0.023,0.081-0.046,0.121-0.069
		C22.265,59.878,22.259,59.93,22.254,59.981z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M33.71,31.709c-0.014,0.117-0.038,0.209-0.158,0.064C33.604,31.752,33.657,31.731,33.71,31.709z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M25.873,49.434c-0.01,0.101-0.003,0.235-0.153,0.085C25.771,49.49,25.822,49.462,25.873,49.434z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M46.61,63.722c-0.024,0.048-0.048,0.095-0.072,0.143c-0.025-0.036-0.049-0.073-0.074-0.109
		C46.513,63.745,46.561,63.733,46.61,63.722z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M37.353,35.34c-0.156-0.123-0.055-0.145,0.063-0.16C37.395,35.233,37.374,35.286,37.353,35.34z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M38.94,47.854c0.016-0.112,0.04-0.207,0.159-0.065C39.047,47.81,38.993,47.832,38.94,47.854z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M51.379,43.761c0.017-0.125,0.101-0.14,0.204-0.124C51.515,43.679,51.447,43.72,51.379,43.761z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M34.791,41.155c0.022-0.037,0.044-0.075,0.066-0.112c0.029,0.042,0.058,0.085,0.087,0.128
		C34.893,41.165,34.842,41.16,34.791,41.155z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M24.033,67.905c0.126-0.137,0.141-0.033,0.156,0.072C24.137,67.953,24.085,67.929,24.033,67.905z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M33.869,41.001c0.022-0.037,0.045-0.075,0.067-0.112c0.029,0.043,0.058,0.086,0.086,0.129
		C33.971,41.013,33.92,41.007,33.869,41.001z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M39.389,14.871c-0.01,0.096,0.006,0.237-0.15,0.091C39.289,14.932,39.339,14.901,39.389,14.871z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M18.967,58.513c-0.02,0.028-0.04,0.056-0.06,0.084C18.927,58.569,18.947,58.541,18.967,58.513z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M33.794,21.511c-0.01,0.037-0.019,0.074-0.028,0.111c-0.02-0.008-0.037-0.02-0.052-0.035
		C33.74,21.562,33.767,21.537,33.794,21.511z"
        />
        <path
          fill={theme.palette.placeholder}
          d="M33.713,21.588c0.015,0.015,0.033,0.027,0.052,0.035c0,0-0.024,0.025-0.024,0.025
		C33.724,21.632,33.714,21.612,33.713,21.588z"
        />
        <path
          fill={theme.palette.placeholder}
          d="M72.727,87.955c0,0-0.023-0.019-0.023-0.019S72.727,87.955,72.727,87.955z"
        />
        <path
          fill={theme.palette.placeholder}
          d="M52.452,23.085c-0.022-0.016-0.044-0.032-0.065-0.047C52.408,23.053,52.43,23.069,52.452,23.085z"
        />
        <path
          fill={theme.palette.primary.contrastText}
          d="M54.843,13.593c0.023-0.024,0.047-0.048,0.07-0.072C54.89,13.545,54.866,13.569,54.843,13.593z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M18.906,61.211c0.02,0.028,0.041,0.056,0.061,0.084C18.946,61.267,18.926,61.239,18.906,61.211z"
        />
        <path
          fill={theme.palette.placeholder}
          d="M52.913,23.242c-0.027-0.015-0.055-0.03-0.082-0.045C52.858,23.212,52.886,23.227,52.913,23.242z"
        />
        <path
          fill={theme.palette.primary.contrastText}
          d="M55.854,12.289c0.019-0.025,0.038-0.051,0.057-0.076C55.892,12.238,55.873,12.263,55.854,12.289z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M79.336,55.377c0.019-0.026,0.037-0.052,0.056-0.078C79.373,55.325,79.354,55.351,79.336,55.377z"
        />
        <path
          fill={theme.palette.placeholder}
          d="M53.067,23.318c-0.022-0.016-0.045-0.033-0.067-0.049C53.022,23.285,53.044,23.301,53.067,23.318z"
        />
        <path
          fill={theme.palette.placeholder}
          d="M53.222,23.412c-0.024-0.022-0.047-0.044-0.071-0.067C53.174,23.368,53.198,23.39,53.222,23.412z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M49.15,9.542c-0.026,0.019-0.052,0.038-0.077,0.057C49.098,9.58,49.124,9.561,49.15,9.542z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M31.024,40.244c0.026,0.021,0.052,0.042,0.078,0.063C31.076,40.286,31.05,40.265,31.024,40.244z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M73.961,92.096c-0.021,0.022-0.041,0.045-0.062,0.067C73.92,92.141,73.94,92.118,73.961,92.096z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M30.332,33.59c-0.011,0.021-0.027,0.036-0.048,0.045C30.3,33.62,30.316,33.605,30.332,33.59z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M33.79,31.654c-0.028,0.02-0.056,0.04-0.084,0.06C33.734,31.694,33.762,31.674,33.79,31.654z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M38.949,93.694c0.022,0.026,0.044,0.052,0.066,0.078C38.993,93.746,38.971,93.72,38.949,93.694z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M51.314,93.696c0.022,0.024,0.044,0.049,0.066,0.073C51.357,93.745,51.336,93.72,51.314,93.696z"
        />
        <path
          fill={theme.palette.primary.main}
          d="M51.288,86.481c0,0.069,0,0.069,0,0.069l0.081,0.038l0.01-0.107"
        />
      </g>
    </SvgIcon>
  );
}
