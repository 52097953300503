import { Box, Typography, IconButton } from "@mui/material";
import { FooterLayoutQueryFragment } from "../FooterLayoutQuery.gql";
import { FooterAddressQueryFragment } from "./FooterAddressQueryFragment.gql";
import Link from "@mui/material/Link";
import Logo from "@/components/smbois/Icons/Logo";

type FooterAddressProps = FooterLayoutQueryFragment &
  FooterAddressQueryFragment;

export default function FooterAddress(props: FooterAddressProps) {
  const { storeConfig, links, otherLinks } = props;

  return (
    <Box
      sx={{
        backgroundColor: "text.primary",
        borderTop: "1px solid #E4E4E4",
        borderBottom: "1px solid #E4E4E4",
        minHeight: "197px",
        padding: "40px 25px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          minHeight: "197px",
          flexWrap: "wrap",
          gap: 6,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            href="/"
            color="primary"
            sx={{ display: { xs: "none", md: "flex" }, ml: "-25px" }}
            aria-label="SMBois"
          >
            <Logo
              sx={{
                alignSelf: "center",
                justifySelf: "center",
                width: { xs: "40px", md: "90px" },
                height: { xs: "55px", md: "100px" },
              }}
            />
          </IconButton>
          <Box>
            <Typography
              variant="body1"
              component="p"
              sx={{
                color: "gradient.contrastText",
                padding: "0 0 10px 0",
              }}
            >
              {storeConfig?.storeName} - {storeConfig?.storeStreetLine2}
            </Typography>
            <Typography
              component="p"
              variant="small"
              sx={{
                color: "gradient.contrastText",
                textAlign: "left",
                lineHeight: 1.5,
              }}
            >
              ADRESSE DU SHOWROOM : {storeConfig?.storeStreetLine1},{" "}
              {storeConfig?.storePostcode} {storeConfig?.storeCity}
              <br />
              {storeConfig?.storeVatNumber}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexWrap: "wrap",
            gap: 6,
            flexDirection: "column",
            [theme.breakpoints.up("xl")]: {
              gap: 3,
            },
          })}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 3,
            }}
          >
            {links?.map((link, index) => (
              <Box key={index}>
                <Link
                  key={index}
                  href={link?.url || "#"}
                  variant="small"
                  sx={{
                    cursor: "pointer",
                    color: "gradient.contrastText",
                    textDecoration: "none",
                  }}
                >
                  {link?.title}
                </Link>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 3,
            }}
          >
            {otherLinks?.map((link, index) => (
              <Box key={index}>
                <Link
                  key={index}
                  href={link?.url || "#"}
                  variant="small"
                  sx={{
                    cursor: "pointer",
                    color: "gradient.contrastText",
                    textDecoration: "none",
                  }}
                >
                  {link?.title}
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
