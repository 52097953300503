import { SvgIcon, useTheme } from "@mui/material";

export default function LockKeyRed(props) {
  const theme = useTheme();
  return (
    <SvgIcon {...props} width="60" height="60" viewBox="0 0 60 60" fill="none">
      <circle cx="30" cy="30" r="30" fill="url(#paint0_linear_1353_4412)" />
      <path
        d="M30 34C31.3807 34 32.5 32.8807 32.5 31.5C32.5 30.1193 31.3807 29 30 29C28.6193 29 27.5 30.1193 27.5 31.5C27.5 32.8807 28.6193 34 30 34Z"
        stroke={theme.palette.primary.contrastText}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 34V37"
        stroke={theme.palette.primary.contrastText}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40 25H20C19.4477 25 19 25.4477 19 26V40C19 40.5523 19.4477 41 20 41H40C40.5523 41 41 40.5523 41 40V26C41 25.4477 40.5523 25 40 25Z"
        stroke={theme.palette.primary.contrastText}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5 25V20.5C25.5 19.3065 25.9741 18.1619 26.818 17.318C27.6619 16.4741 28.8065 16 30 16C31.1935 16 32.3381 16.4741 33.182 17.318C34.0259 18.1619 34.5 19.3065 34.5 20.5V25"
        stroke={theme.palette.primary.contrastText}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1353_4412"
          x1="30"
          y1="0"
          x2="30"
          y2="60"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#EB3943" />
          <stop offset="1" stopColor="#FF6666" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
