import { SvgIcon, useTheme } from "@mui/material";

export default function ClockAfternoonRed(props) {
  const theme = useTheme();
  return (
    <SvgIcon {...props} width="60" height="60" viewBox="0 0 60 60" fill="none">
      <circle cx="30" cy="30" r="30" fill="url(#paint0_linear_1353_4394)" />
      <path
        d="M30 42C36.6274 42 42 36.6274 42 30C42 23.3726 36.6274 18 30 18C23.3726 18 18 23.3726 18 30C18 36.6274 23.3726 42 30 42Z"
        stroke={theme.palette.primary.contrastText}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M30 30H37"
        stroke={theme.palette.primary.contrastText}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.95 34.95L30 30"
        stroke={theme.palette.primary.contrastText}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1353_4394"
          x1="30"
          y1="0"
          x2="30"
          y2="60"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#EB3943" />
          <stop offset="1" stopColor="#FF6666" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
