import Facebook from "@/components/smbois/Icons/Facebook";
import Instagram from "@/components/smbois/Icons/Instagram";
import Youtube from "@/components/smbois/Icons/Youtube";
import { Box, IconButton, Typography, Link } from "@mui/material";
import { useRouter } from "next/router";
import Linkedin from "../../Icons/Linkedin";
import Pinterest from "../../Icons/Pinterest";
import { FooterLayoutQueryFragment } from "../FooterLayoutQuery.gql";
import { FooterBase } from "./FooterBase";
import { FooterSocialQueryFragment } from "./FooterSocialQueryFragment.gql";

export type FooterProps = FooterSocialQueryFragment & FooterLayoutQueryFragment;

export function FooterSocial(props: FooterProps) {
  const { getLegalLinks, getSocialLinks, storeConfig } = props;
  const router = useRouter();
  const socialIcons = {
    facebook: <Facebook sx={{ width: "30px", height: "30px" }} />,
    instagram: <Instagram sx={{ width: "30px", height: "30px" }} />,
    youtube: <Youtube sx={{ width: "30px", height: "30px" }} />,
    linkedin: <Linkedin sx={{ width: "30px", height: "30px" }} />,
    pinterest: <Pinterest sx={{ width: "30px", height: "30px" }} />,
  };

  return (
    <FooterBase
      socialLinks={getSocialLinks?.map((link, index) => (
        <IconButton
          key={index}
          onClick={() => link?.url && router.push(link.url)}
          size="medium"
          edge="start"
          aria-label={link?.icon as string}
          sx={{
            width: "48px",
            height: "48px",
            maxWidth: "48px",
            maxHeight: "48px",
          }}
        >
          {socialIcons[link?.icon as string]}
        </IconButton>
      ))}
      copyright={
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            flexWrap: "wrap",
            paddingBottom: "80px",
            [theme.breakpoints.up("xl")]: {
              paddingBottom: "0px",
            },
          })}
        >
          <Typography
            variant="small"
            component="div"
            sx={{ color: "gradient.contrastText" }}
          >
            {storeConfig?.copyright}
          </Typography>
        </Box>
      }
      legalLinks={
        <Box sx={{ lineHeight: 1.5 }}>
          {getLegalLinks?.map((link) => (
            <Link
              variant="small"
              key={link?.title}
              href={link?.url}
              color="gradient.contrastText"
              sx={{ textDecoration: "none", padding: "0 10px 10px 0" }}
            >
              {link?.title}
            </Link>
          ))}
          <Link
            variant="small"
            href="sitemap.xml"
            target="_blank"
            color="gradient.contrastText"
            sx={{ textDecoration: "none", padding: "0 10px 10px 0" }}
          >
            Plan du site
          </Link>
        </Box>
      }
      sx={{ backgroundColor: "text.primary", pb: "70px" }}
    />
  );
}
