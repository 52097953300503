import { SvgIcon, useTheme } from "@mui/material";

export default function TruckRed(props) {
  const theme = useTheme();
  return (
    <SvgIcon {...props} width="60" height="60" viewBox="0 0 60 60" fill="none">
      <circle cx="30" cy="30" r="30" fill="url(#paint0_linear_1353_4401)" />
      <path
        d="M36 24H41.325C41.5242 23.9987 41.7192 24.0577 41.8843 24.1693C42.0494 24.2808 42.1769 24.4397 42.25 24.625L44 29"
        stroke={theme.palette.primary.contrastText}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 32H36"
        stroke={theme.palette.primary.contrastText}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.5 41C39.1569 41 40.5 39.6569 40.5 38C40.5 36.3431 39.1569 35 37.5 35C35.8431 35 34.5 36.3431 34.5 38C34.5 39.6569 35.8431 41 37.5 41Z"
        stroke={theme.palette.primary.contrastText}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M22.5 41C24.1569 41 25.5 39.6569 25.5 38C25.5 36.3431 24.1569 35 22.5 35C20.8431 35 19.5 36.3431 19.5 38C19.5 39.6569 20.8431 41 22.5 41Z"
        stroke={theme.palette.primary.contrastText}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M34.5 38H25.5"
        stroke={theme.palette.primary.contrastText}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 38H17C16.7348 38 16.4804 37.8946 16.2929 37.7071C16.1054 37.5196 16 37.2652 16 37V23C16 22.7348 16.1054 22.4804 16.2929 22.2929C16.4804 22.1054 16.7348 22 17 22H36V35.4"
        stroke={theme.palette.primary.contrastText}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36 29H44V37C44 37.2652 43.8946 37.5196 43.7071 37.7071C43.5196 37.8946 43.2652 38 43 38H40.5"
        stroke={theme.palette.primary.contrastText}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1353_4401"
          x1="30"
          y1="0"
          x2="30"
          y2="60"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#EB3943" />
          <stop offset="1" stopColor="#FF6666" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
