import { SvgIcon, useTheme } from "@mui/material";

export default function StarRed(props) {
  const theme = useTheme();
  return (
    <SvgIcon {...props} width="60" height="60" viewBox="0 0 60 60" fill="none">
      <circle cx="30" cy="30" r="30" fill="url(#paint0_linear_1353_4425)" />
      <path
        d="M30.55 37.8375L36.85 41.8375C37.6625 42.35 38.6625 41.5875 38.425 40.65L36.6 33.475C36.5506 33.2762 36.5585 33.0674 36.6226 32.8728C36.6868 32.6781 36.8046 32.5056 36.9625 32.375L42.6125 27.6625C43.35 27.05 42.975 25.8125 42.0125 25.75L34.6375 25.275C34.4363 25.2633 34.2428 25.1933 34.0808 25.0734C33.9187 24.9535 33.7951 24.789 33.725 24.6L30.975 17.675C30.9022 17.4749 30.7696 17.302 30.5952 17.1799C30.4207 17.0577 30.2129 16.9922 30 16.9922C29.787 16.9922 29.5793 17.0577 29.4048 17.1799C29.2304 17.302 29.0978 17.4749 29.025 17.675L26.275 24.6C26.2049 24.789 26.0813 24.9535 25.9192 25.0734C25.7572 25.1933 25.5637 25.2633 25.3625 25.275L17.9875 25.75C17.025 25.8125 16.65 27.05 17.3875 27.6625L23.0375 32.375C23.1954 32.5056 23.3132 32.6781 23.3774 32.8728C23.4415 33.0674 23.4493 33.2762 23.4 33.475L21.7125 40.125C21.425 41.25 22.625 42.1625 23.5875 41.55L29.45 37.8375C29.6144 37.733 29.8052 37.6775 30 37.6775C30.1948 37.6775 30.3856 37.733 30.55 37.8375Z"
        stroke={theme.palette.primary.contrastText}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1353_4425"
          x1="30"
          y1="0"
          x2="30"
          y2="60"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#EB3943" />
          <stop offset="1" stopColor="#FF6666" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
