import { SvgIcon, useTheme } from "@mui/material";

export default function HeadsetRed(props) {
  const theme = useTheme();
  return (
    <SvgIcon {...props} width="60" height="60" viewBox="0 0 60 60" fill="none">
      <circle cx="30" cy="30" r="30" fill="url(#paint0_linear_1353_4430)" />
      <path
        d="M42.1875 29.9999H38.1875C37.6571 29.9999 37.1484 30.2106 36.7733 30.5857C36.3982 30.9608 36.1875 31.4695 36.1875 31.9999V36.9999C36.1875 37.5304 36.3982 38.0391 36.7733 38.4141C37.1484 38.7892 37.6571 38.9999 38.1875 38.9999H40.1875C40.7179 38.9999 41.2266 38.7892 41.6017 38.4141C41.9768 38.0391 42.1875 37.5304 42.1875 36.9999V29.9999ZM42.1875 29.9999C42.1876 28.4156 41.8739 26.847 41.2646 25.3845C40.6553 23.922 39.7624 22.5947 38.6375 21.4791C37.5126 20.3635 36.1779 19.4817 34.7104 18.8845C33.2429 18.2874 31.6718 17.9867 30.0875 17.9999C28.5043 17.9884 26.9344 18.2903 25.4684 18.8881C24.0024 19.486 22.6692 20.3681 21.5456 21.4836C20.422 22.599 19.5303 23.9258 18.9217 25.3874C18.3132 26.8491 18 28.4167 18 29.9999M18 29.9999V36.9999C18 37.5304 18.2107 38.0391 18.5858 38.4141C18.9609 38.7892 19.4696 38.9999 20 38.9999H22C22.5304 38.9999 23.0391 38.7892 23.4142 38.4141C23.7893 38.0391 24 37.5304 24 36.9999V31.9999C24 31.4695 23.7893 30.9608 23.4142 30.5857C23.0391 30.2106 22.5304 29.9999 22 29.9999H18Z"
        stroke={theme.palette.primary.contrastText}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.1875 37V40C42.1875 41.0609 41.7661 42.0783 41.0159 42.8284C40.2658 43.5786 39.2484 44 38.1875 44H31"
        stroke={theme.palette.primary.contrastText}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1353_4430"
          x1="30"
          y1="0"
          x2="30"
          y2="60"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#EB3943" />
          <stop offset="1" stopColor="#FF6666" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
