import { SvgIcon, useTheme } from "@mui/material";

export default function HandPointingRed(props) {
  const theme = useTheme();
  return (
    <SvgIcon {...props} width="60" height="60" viewBox="0 0 60 60" fill="none">
      <circle cx="30" cy="30" r="30" fill="url(#paint0_linear_1353_4420)" />
      <path
        d="M33.9998 28V26.5C33.9998 25.837 33.7364 25.2011 33.2675 24.7322C32.7987 24.2634 32.1628 24 31.4998 24C30.8367 24 30.2009 24.2634 29.732 24.7322C29.2632 25.2011 28.9998 25.837 28.9998 26.5V18.5C28.9998 17.837 28.7364 17.2011 28.2675 16.7322C27.7987 16.2634 27.1628 16 26.4998 16C25.8367 16 25.2009 16.2634 24.732 16.7322C24.2632 17.2011 23.9998 17.837 23.9998 18.5V33.6625L21.2623 28.9125C20.9308 28.3373 20.3843 27.9174 19.7432 27.7451C19.102 27.5728 18.4187 27.6622 17.8435 27.9937C17.2683 28.3253 16.8484 28.8717 16.6761 29.5128C16.5038 30.154 16.5933 30.8373 16.9248 31.4125C20.9998 40 23.4748 43 28.9998 43C30.313 43 31.6134 42.7413 32.8266 42.2388C34.0399 41.7362 35.1423 40.9997 36.0709 40.0711C36.9994 39.1425 37.736 38.0401 38.2386 36.8268C38.7411 35.6136 38.9998 34.3132 38.9998 33V28C38.9998 27.337 38.7364 26.7011 38.2675 26.2322C37.7987 25.7634 37.1628 25.5 36.4998 25.5C35.8367 25.5 35.2009 25.7634 34.732 26.2322C34.2632 26.7011 33.9998 27.337 33.9998 28Z"
        stroke={theme.palette.primary.contrastText}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1353_4420"
          x1="30"
          y1="0"
          x2="30"
          y2="60"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#EB3943" />
          <stop offset="1" stopColor="#FF6666" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
