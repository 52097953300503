import { extendableComponent } from "@graphcommerce/next-ui";
import { Box, Container, ContainerProps } from "@mui/material";
import React from "react";

export type FooterProps = {
  legalLinks?: React.ReactElement;
  socialLinks?: React.ReactNode;
  customerService?: React.ReactNode;
  copyright?: React.ReactElement;
} & Omit<ContainerProps, "children">;

const { classes, selectors } = extendableComponent("Footer", [
  "root",
  "social",
  "legalLinks",
  "support",
  "copyright",
] as const);

export function FooterBase(props: FooterProps) {
  const {
    socialLinks,
    legalLinks,
    customerService,
    sx = [],
    ...containerProps
  } = props;

  return (
    <Container
      sx={[
        (theme) => ({
          borderTop: `1px solid ${theme.palette.divider}`,
          padding: `${theme.spacings.lg} ${theme.page.horizontal} ${theme.page.vertical}`,
          justifyItems: "center",
          display: "flex",
          flexWrap: "wrap",
          gap: 5,
          justifyContent: "space-between",

          [theme.breakpoints.up("md")]: {
            gap: theme.spacings.sm,
            gridTemplateAreas: `
              'social switcher'
              'links support'
            `,
            justifyItems: "start",
            padding: `${theme.page.vertical} ${theme.page.horizontal}`,
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      maxWidth={false}
      className={classes.root}
      {...containerProps}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          gap: 1,
          padding: "0 20px",
        }}
      >
        <Box
          sx={(theme) => ({
            display: "grid",
            justifyContent: "start",
            gridAutoFlow: "column",
            gridArea: "social",
            gap: { xs: `0 ${theme.spacings.xs}`, md: `0 ${theme.spacings.xs}` },
            "& > *": {
              minWidth: "min-content",
            },
          })}
          className={classes.social}
        >
          {socialLinks}
        </Box>
        {legalLinks}
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "100%",
          [theme.breakpoints.up("md")]: { width: "auto" },
        })}
      >
        <Box className={classes.support} sx={{ width: "100%" }}>
          {customerService}
        </Box>
      </Box>
    </Container>
  );
}

FooterBase.selectors = selectors;
