import { Box } from "@mui/material";
import FooterAddress from "./Content/FooterAddress";
import { FooterAddressQueryFragment } from "./Content/FooterAddressQueryFragment.gql";
import FooterCenter from "./Content/FooterCenter";
import { FooterCenterQueryFragment } from "./Content/FooterCenterQuery.gql";
import { FooterSocial } from "./Content/FooterSocial";
import { FooterSocialQueryFragment } from "./Content/FooterSocialQueryFragment.gql";
import FooterTop from "./Content/FooterTop";
import { FooterLayoutQueryFragment } from "./FooterLayoutQuery.gql";
import { FooterTopQueryFragment } from "./Content/FooterTopQuery.gql";

type FooterLayoutProps = FooterLayoutQueryFragment &
  FooterSocialQueryFragment &
  FooterAddressQueryFragment &
  FooterTopQueryFragment &
  FooterCenterQueryFragment;

export default function FooterLayout(props: FooterLayoutProps) {
  const {
    storeConfig,
    getLegalLinks,
    getSocialLinks,
    partners,
    links,
    otherLinks,
    reinsuranceLink,
  } = props;
  return (
    <Box>
      <FooterTop reinsuranceLink={reinsuranceLink} />
      <FooterCenter partners={partners} />
      <FooterAddress
        storeConfig={storeConfig}
        links={links}
        otherLinks={otherLinks}
      />
      <FooterSocial
        getLegalLinks={getLegalLinks}
        getSocialLinks={getSocialLinks}
        storeConfig={storeConfig}
      />
    </Box>
  );
}
