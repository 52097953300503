import { SvgIcon } from "@mui/material";

export default function Facebook(props) {
  return (
    <SvgIcon
      {...props}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      aria-label="Facebook"
    >
      <circle cx="15" cy="15" r="15" fill="white" />
      <path
        d="M21.825 9H18.6C17.1745 9 15.8073 9.56629 14.7993 10.5743C13.7913 11.5823 13.225 12.9495 13.225 14.375V17.6H10V21.9H13.225V30.5H17.525V21.9H20.75L21.825 17.6H17.525V14.375C17.525 14.0899 17.6383 13.8165 17.8399 13.6149C18.0415 13.4133 18.3149 13.3 18.6 13.3H21.825V9Z"
        fill="black"
      />
    </SvgIcon>
  );
}
