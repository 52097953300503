import { SvgIcon } from "@mui/material";

export default function Instagram(props) {
  return (
    <SvgIcon
      {...props}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      aria-label="Instagram"
    >
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
        <circle cx="15" cy="15" r="15" fill="white" />
        <path
          d="M19.25 7H11.75C9.67893 7 8 8.67893 8 10.75V18.25C8 20.3211 9.67893 22 11.75 22H19.25C21.3211 22 23 20.3211 23 18.25V10.75C23 8.67893 21.3211 7 19.25 7Z"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.4998 14.0272C18.5923 14.6514 18.4857 15.2889 18.1951 15.849C17.9045 16.4091 17.4446 16.8633 16.881 17.147C16.3174 17.4307 15.6786 17.5294 15.0556 17.4292C14.4326 17.3289 13.8571 17.0348 13.4109 16.5886C12.9647 16.1424 12.6706 15.5669 12.5703 14.9439C12.4701 14.3209 12.5688 13.6822 12.8525 13.1185C13.1362 12.5549 13.5904 12.095 14.1505 11.8044C14.7106 11.5138 15.3481 11.4072 15.9723 11.4997C16.609 11.5942 17.1984 11.8908 17.6536 12.346C18.1087 12.8011 18.4054 13.3905 18.4998 14.0272Z"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.625 10.375H19.6325"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
